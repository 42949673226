// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import { LoggableContent, LoggingConfig } from '../models';

export interface UploadLoggingUsingPOSTRequest {
  loggableContent: LoggableContent;
}

/**
 * no description
 */
export class LogControllerApi extends BaseAPI {
  /**
   * getLoggingConfig
   * Allows the application to download a logging config for a user. Fallback is a simple standard config.
   */
  getLoggingConfigUsingGET = (): Observable<LoggingConfig> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<LoggingConfig>({
      path: '/loggingConfig',
      method: 'GET',
      headers
    });
  };

  /**
   * uploadLogging
   * Used by the logging mechanism to upload a block of logging entries.
   */
  uploadLoggingUsingPOST = (requestParameters: UploadLoggingUsingPOSTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'loggableContent', 'uploadLoggingUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/logging',
      method: 'POST',
      headers,
      body: requestParameters.loggableContent
    });
  };
}
