// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
  /**
   * @type {string}
   * @memberof UserSettings
   */
  csvSeparator?: UserSettingsCsvSeparatorEnum;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  csvSeparatorChanged?: Date;
  /**
   * @type {string}
   * @memberof UserSettings
   */
  deviceView?: UserSettingsDeviceViewEnum;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  deviceViewChanged?: Date;
  /**
   * @type {string}
   * @memberof UserSettings
   */
  heartbeatView?: UserSettingsHeartbeatViewEnum;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  heartbeatViewChanged?: Date;
  /**
   * @type {string}
   * @memberof UserSettings
   */
  language?: UserSettingsLanguageEnum;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  languageChanged?: Date;
  /**
   * @type {Array<string>}
   * @memberof UserSettings
   */
  pendingLoggers?: Array<string>;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  pendingLoggersChanged?: Date;
  /**
   * @type {string}
   * @memberof UserSettings
   */
  phoneNumberReminder?: UserSettingsPhoneNumberReminderEnum;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  phoneNumberReminderChanged?: Date;
  /**
   * @type {Array<string>}
   * @memberof UserSettings
   */
  readMessages?: Array<string>;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  readMessagesChanged?: Date;
  /**
   * @type {string}
   * @memberof UserSettings
   */
  sessionRenew?: UserSettingsSessionRenewEnum;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  sessionRenewChanged?: Date;
  /**
   * @type {string}
   * @memberof UserSettings
   */
  termsOfUseVersion?: string;
  /**
   * @type {Date}
   * @memberof UserSettings
   */
  termsOfUseVersionChanged?: Date;
  /**
   * @type {boolean}
   * @memberof UserSettings
   */
  valid?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum UserSettingsCsvSeparatorEnum {
  Comma = 'Comma',
  Semicolon = 'Semicolon'
}
/**
 * @export
 * @enum {string}
 */
export enum UserSettingsDeviceViewEnum {
  GROUP = 'GROUP',
  STRUCTURE = 'STRUCTURE'
}
/**
 * @export
 * @enum {string}
 */
export enum UserSettingsHeartbeatViewEnum {
  PIE = 'PIE',
  BAR = 'BAR'
}
/**
 * @export
 * @enum {string}
 */
export enum UserSettingsLanguageEnum {
  DeDE = 'deDE',
  EnUS = 'enUS',
  EnGB = 'enGB',
  FrFR = 'frFR',
  EsES = 'esES',
  PlPL = 'plPL',
  CsCZ = 'csCZ',
  SlSI = 'slSI',
  SkSK = 'skSK',
  HuHU = 'huHU',
  BgBG = 'bgBG',
  RoRO = 'roRO',
  ElGR = 'elGR'
}
/**
 * @export
 * @enum {string}
 */
export enum UserSettingsPhoneNumberReminderEnum {
  YES = 'YES',
  NO = 'NO',
  ASK = 'ASK'
}
/**
 * @export
 * @enum {string}
 */
export enum UserSettingsSessionRenewEnum {
  YES = 'YES',
  NO = 'NO',
  ASK = 'ASK'
}
