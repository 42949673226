// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  AllMeasureSitesResponse,
  ApplianceGroupsRequest,
  ApplianceGroupsResponse,
  ApplianceResponse,
  ApplianceStatesResponse,
  BinderApplianceInfoResponse,
  ConnectivityResponse,
  ErrorDto,
  GatewayResponse,
  HttpStatusCode,
  MasterDataApplianceInfoResponse,
  RegisteredBinderAppliancesResponse,
  SmartModuleConnectionResponse,
  SmartModuleResponse,
  SmartModuleState,
  UpdateGatewayInfoRequest,
  UpdateMeasureSiteRequest
} from '../models';

export interface AddMeasureSiteToGroupUsingPUTRequest {
  groupId: string;
  virtualFridgeId: string;
}

export interface CheckApplianceUsingGETRequest {
  articleNumber: string;
}

export interface DeleteMeasureSiteAddressUsingDELETERequest {
  virtualFridgeId: string;
}

export interface GetApplianceInfoBySerialNumberUsingGETRequest {
  serialNumber: string;
}

export interface GetBinderApplianceInfoUsingGETRequest {
  macAddress: string;
}

export interface GetMasterDataPictureBySerialNrUsingGETRequest {
  serialNumber: string;
}

export interface GetMeasureSiteAsApplianceResponseUsingGETRequest {
  locale: string;
  virtualFridgeId: string;
}

export interface GetRegisteredBinderAppliancesUsingGETRequest {
  gatewayId: string;
}

export interface GetSmartModuleApplianceUsingGETRequest {
  serialNumber: string;
}

export interface GetSmartModuleConnectionStateUsingGETRequest {
  serialNumber: string;
}

export interface GetSmartModuleStateUsingGETRequest {
  virtualFridgeId: string;
}

export interface SaveGroupsUsingPOSTRequest {
  applianceGroupsRequest: ApplianceGroupsRequest;
}

export interface UpdateGatewayInfoUsingPUTRequest {
  gatewayId: string;
  updateGatewayInfoRequest: UpdateGatewayInfoRequest;
}

export interface UpdateMeasureSiteUsingPUTRequest {
  updateMeasureSiteRequest: UpdateMeasureSiteRequest;
}

/**
 * no description
 */
export class ApplianceControllerApi extends BaseAPI {
  /**
   * addMeasureSiteToGroup
   * Add measure site to group.
   */
  addMeasureSiteToGroupUsingPUT = (requestParameters: AddMeasureSiteToGroupUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'groupId', 'addMeasureSiteToGroupUsingPUT');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'addMeasureSiteToGroupUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/measuresite/{virtualFridgeId}/{groupId}'
        .replace('{groupId}', encodeURI(requestParameters.groupId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'PUT',
      headers
    });
  };

  /**
   * checkAppliance
   * Checks whether an appliance with the given article number is compatible with SM.
   */
  checkApplianceUsingGET = (requestParameters: CheckApplianceUsingGETRequest): Observable<ConnectivityResponse> => {
    throwIfRequired(requestParameters, 'articleNumber', 'checkApplianceUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ConnectivityResponse>({
      path: '/public/appliance/check/{articleNumber}'.replace(
        '{articleNumber}',
        encodeURI(requestParameters.articleNumber)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * deleteMeasureSiteAddress
   * Deletes the address of a measuresite.
   */
  deleteMeasureSiteAddressUsingDELETE = (
    requestParameters: DeleteMeasureSiteAddressUsingDELETERequest
  ): Observable<HttpStatusCode> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'deleteMeasureSiteAddressUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId })
    };

    return this.request<HttpStatusCode>({
      path: '/measuresite/address',
      method: 'DELETE',
      headers,
      query
    });
  };

  /**
   * getAllGateways
   * Get all gateways for the organization.
   */
  getAllGatewaysUsingGET = (): Observable<GatewayResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<GatewayResponse>({
      path: '/appliance/gateways',
      method: 'GET',
      headers
    });
  };

  /**
   * getAllGroups
   * Gets all appliance groups that the user owns.
   */
  getAllGroupsUsingGET = (): Observable<ApplianceGroupsResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceGroupsResponse>({
      path: '/appliance/groups',
      method: 'GET',
      headers
    });
  };

  /**
   * getAllMeasureSites
   * Get the existing measure sites for an organization.
   */
  getAllMeasureSitesUsingGET = (): Observable<AllMeasureSitesResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AllMeasureSitesResponse>({
      path: '/appliance/measuresites/organization',
      method: 'GET',
      headers
    });
  };

  /**
   * getAllStates
   * Gets states for all appliances that the user owns.
   */
  getAllStatesUsingGET = (): Observable<ApplianceStatesResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceStatesResponse>({
      path: '/appliance/states',
      method: 'GET',
      headers
    });
  };

  /**
   * getApplianceInfoBySerialNumber
   * Get appliance info by serial number. Latter must have the following format: XX.XXX.XXX.X.
   */
  getApplianceInfoBySerialNumberUsingGET = (
    requestParameters: GetApplianceInfoBySerialNumberUsingGETRequest
  ): Observable<MasterDataApplianceInfoResponse> => {
    throwIfRequired(requestParameters, 'serialNumber', 'getApplianceInfoBySerialNumberUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MasterDataApplianceInfoResponse>({
      path: '/masterdata/applianceinfo/{serialNumber}'.replace(
        '{serialNumber}',
        encodeURI(requestParameters.serialNumber)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getBinderApplianceInfo
   * Get binder appliance info.
   */
  getBinderApplianceInfoUsingGET = (
    requestParameters: GetBinderApplianceInfoUsingGETRequest
  ): Observable<BinderApplianceInfoResponse> => {
    throwIfRequired(requestParameters, 'macAddress', 'getBinderApplianceInfoUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<BinderApplianceInfoResponse>({
      path: '/binderappliances/{macAddress}/info'.replace('{macAddress}', encodeURI(requestParameters.macAddress)),
      method: 'GET',
      headers
    });
  };

  /**
   * getDefaultMasterDataPicture
   * Get masterdata default picture.
   */
  getDefaultMasterDataPictureUsingGET = (): Observable<Blob> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Blob>({
      path: '/masterdata/picture/default',
      method: 'GET',
      headers,
      responseType: 'blob'
    });
  };

  /**
   * getMasterDataPictureBySerialNr
   * Get masterdata picture by serial nr.
   */
  getMasterDataPictureBySerialNrUsingGET = (
    requestParameters: GetMasterDataPictureBySerialNrUsingGETRequest
  ): Observable<Blob> => {
    throwIfRequired(requestParameters, 'serialNumber', 'getMasterDataPictureBySerialNrUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Blob>({
      path: '/masterdata/picture/{serialNumber}'.replace('{serialNumber}', encodeURI(requestParameters.serialNumber)),
      method: 'GET',
      headers,
      responseType: 'blob'
    });
  };

  /**
   * getMeasureSiteAsApplianceResponse
   * Get measure site info by virtualFridgeId.
   */
  getMeasureSiteAsApplianceResponseUsingGET = (
    requestParameters: GetMeasureSiteAsApplianceResponseUsingGETRequest
  ): Observable<ApplianceResponse> => {
    throwIfRequired(requestParameters, 'locale', 'getMeasureSiteAsApplianceResponseUsingGET');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getMeasureSiteAsApplianceResponseUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId })
    };

    return this.request<ApplianceResponse>({
      path: '/appliance/measuresite/{locale}'.replace('{locale}', encodeURI(requestParameters.locale)),
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getRegisteredBinderAppliances
   * Get registered to a gateway binder appliance.
   */
  getRegisteredBinderAppliancesUsingGET = (
    requestParameters: GetRegisteredBinderAppliancesUsingGETRequest
  ): Observable<RegisteredBinderAppliancesResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getRegisteredBinderAppliancesUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<RegisteredBinderAppliancesResponse>({
      path: '/gateways/{gatewayId}/registeredbinder'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getSmartModuleAppliance
   * Gets Smart module appliance.
   */
  getSmartModuleApplianceUsingGET = (
    requestParameters: GetSmartModuleApplianceUsingGETRequest
  ): Observable<SmartModuleResponse> => {
    throwIfRequired(requestParameters, 'serialNumber', 'getSmartModuleApplianceUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.serialNumber && { serialNumber: requestParameters.serialNumber })
    };

    return this.request<SmartModuleResponse>({
      path: '/appliance/smartmodule',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getSmartModuleConnectionState
   * Gets the connection state of a SmartModuleFridge by serial number
   */
  getSmartModuleConnectionStateUsingGET = (
    requestParameters: GetSmartModuleConnectionStateUsingGETRequest
  ): Observable<SmartModuleConnectionResponse> => {
    throwIfRequired(requestParameters, 'serialNumber', 'getSmartModuleConnectionStateUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleConnectionResponse>({
      path: '/smartmodule/connection/{serialNumber}'.replace(
        '{serialNumber}',
        encodeURI(requestParameters.serialNumber)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getSmartModuleState
   * Gets state for one SmartModule that the user owns.
   */
  getSmartModuleStateUsingGET = (
    requestParameters: GetSmartModuleStateUsingGETRequest
  ): Observable<SmartModuleState> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getSmartModuleStateUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleState>({
      path: '/appliance/smartmodule/state/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * saveGroups
   * Stores/creates the groups as specified in the groups request. Replaces the old group editing completely.
   */
  saveGroupsUsingPOST = (requestParameters: SaveGroupsUsingPOSTRequest): Observable<ApplianceGroupsResponse> => {
    throwIfRequired(requestParameters, 'applianceGroupsRequest', 'saveGroupsUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceGroupsResponse>({
      path: '/admin/appliance/groups/save',
      method: 'POST',
      headers,
      body: requestParameters.applianceGroupsRequest
    });
  };

  /**
   * updateGatewayInfo
   * Update bsdb info with given data.
   */
  updateGatewayInfoUsingPUT = (requestParameters: UpdateGatewayInfoUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'gatewayId', 'updateGatewayInfoUsingPUT');
    throwIfRequired(requestParameters, 'updateGatewayInfoRequest', 'updateGatewayInfoUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/appliance/gateway/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'PUT',
      headers,
      body: requestParameters.updateGatewayInfoRequest
    });
  };

  /**
   * updateMeasureSite
   * Updates a measure site. The UpdateMeasureSiteRequest contains all data.
   */
  updateMeasureSiteUsingPUT = (requestParameters: UpdateMeasureSiteUsingPUTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'updateMeasureSiteRequest', 'updateMeasureSiteUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/measuresite',
      method: 'PUT',
      headers,
      body: requestParameters.updateMeasureSiteRequest
    });
  };
}
