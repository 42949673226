// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  AlarmCommentAddRequest,
  AlarmCommentsRequest,
  AlarmCommentsResponse,
  AlarmReadStateRequest,
  AlarmsCommentsRequest,
  ApplianceAlarmProfileDetailsResponse,
  ApplianceAlarmProfileListResponse,
  ApplianceAlarmProfileUpdateRequest,
  ContinuationAlarmToken,
  ErrorDto,
  FilteredMergedAlarmsResponse,
  GatewayAlarmProfile,
  GatewayAlarmProfileUpdateRequest,
  MergedAlarmStatsResponse,
  MergedAlarmsResponse,
  NotificationSettingsRequest,
  NotificationSettingsResponse,
  SmartModuleAlarmSettingsRequest,
  SmartModuleAlarmSettingsResponse,
  SmartModuleSystemSettingsRequest,
  SmartModuleZoneSettingsRequest
} from '../models';

export interface AddAlarmCommentUsingPOSTRequest {
  alarmCommentAddRequest: AlarmCommentAddRequest;
}

export interface CreateApplianceAlarmProfileDetailsUsingPOSTRequest {
  gatewayId: string;
  virtualFridgeId: string;
  applianceAlarmProfileUpdateRequest: ApplianceAlarmProfileUpdateRequest;
}

export interface CreateSmartModuleAlarmProfileUsingPUTRequest {
  virtualFridgeId: string;
  smartModuleAlarmSettingsRequest: SmartModuleAlarmSettingsRequest;
}

export interface GetAlarmCommentsUsingPOSTRequest {
  alarmCommentsRequest: AlarmCommentsRequest;
}

export interface GetAllAlarmCommentsUsingPOSTRequest {
  alarmsCommentsRequest: AlarmsCommentsRequest;
}

export interface GetApplianceAlarmProfileDetailsUsingGETRequest {
  gatewayId: string;
  virtualFridgeId: string;
}

export interface GetFilteredMergedAlarmListUsingPUTRequest {
  filters: Array<string>;
  continuationAlarmToken: ContinuationAlarmToken;
  from?: Date;
  gatewayIds?: Array<string>;
  groupIds?: Array<string>;
  loggerVfIds?: Array<string>;
  selected?: boolean;
  smVfIds?: Array<string>;
  to?: Date;
  vfIds?: Array<string>;
}

export interface GetGatewayProfileDetailsUsingGETRequest {
  gatewayId: string;
}

export interface GetMergedAlarmListUsingGETRequest {
  from: number;
  to: number;
}

export interface GetSmartModuleAlarmsSettingsUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetSmartModuleNotificationSettingsUsingGETRequest {
  virtualFridgeId: string;
}

export interface SetAlarmReadStateUsingPUTRequest {
  alarmReadStateRequest: AlarmReadStateRequest;
}

export interface UpdateApplianceAlarmProfileUsingPUTRequest {
  applianceAlarmProfileUpdateRequest: ApplianceAlarmProfileUpdateRequest;
}

export interface UpdateGatewayProfileUsingPUTRequest {
  gatewayAlarmProfileUpdateRequest: GatewayAlarmProfileUpdateRequest;
}

export interface UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest {
  virtualFridgeId: string;
  smartModuleZoneSettingsRequest: Array<SmartModuleZoneSettingsRequest>;
}

export interface UpdateSmartModuleNotificationSettingsUsingPUTRequest {
  notificationSettingsRequest: NotificationSettingsRequest;
}

export interface UpdateSmartModuleSystemSettingsUsingPUTRequest {
  virtualFridgeId: string;
  smartModuleSystemSettingsRequest: SmartModuleSystemSettingsRequest;
}

/**
 * no description
 */
export class AlarmControllerApi extends BaseAPI {
  /**
   * addAlarmComment
   * Adds a new comment to an alarm.
   */
  addAlarmCommentUsingPOST = (requestParameters: AddAlarmCommentUsingPOSTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmCommentAddRequest', 'addAlarmCommentUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/new/alarmComment',
      method: 'POST',
      headers,
      body: requestParameters.alarmCommentAddRequest
    });
  };

  /**
   * createApplianceAlarmProfileDetails
   */
  createApplianceAlarmProfileDetailsUsingPOST = (
    requestParameters: CreateApplianceAlarmProfileDetailsUsingPOSTRequest
  ): Observable<ApplianceAlarmProfileDetailsResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'createApplianceAlarmProfileDetailsUsingPOST');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'createApplianceAlarmProfileDetailsUsingPOST');
    throwIfRequired(
      requestParameters,
      'applianceAlarmProfileUpdateRequest',
      'createApplianceAlarmProfileDetailsUsingPOST'
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceAlarmProfileDetailsResponse>({
      path: '/alarmprofile/{virtualFridgeId}/{gatewayId}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'POST',
      headers,
      body: requestParameters.applianceAlarmProfileUpdateRequest
    });
  };

  /**
   * createSmartModuleAlarmProfile
   * Create SmartModule alarm profile.
   */
  createSmartModuleAlarmProfileUsingPUT = (
    requestParameters: CreateSmartModuleAlarmProfileUsingPUTRequest
  ): Observable<SmartModuleAlarmSettingsResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'createSmartModuleAlarmProfileUsingPUT');
    throwIfRequired(requestParameters, 'smartModuleAlarmSettingsRequest', 'createSmartModuleAlarmProfileUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleAlarmSettingsResponse>({
      path: '/admin/smartmodule/alarmssettings/create/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.smartModuleAlarmSettingsRequest
    });
  };

  /**
   * getAlarmComments
   * Gets the existing comments for single alarm.
   */
  getAlarmCommentsUsingPOST = (
    requestParameters: GetAlarmCommentsUsingPOSTRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'alarmCommentsRequest', 'getAlarmCommentsUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/alarm/comment',
      method: 'POST',
      headers,
      body: requestParameters.alarmCommentsRequest
    });
  };

  /**
   * getAlarmProfilesForList
   * Gets the alarm profiles for list.
   */
  getAlarmProfilesForListUsingGET = (): Observable<ApplianceAlarmProfileListResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceAlarmProfileListResponse>({
      path: '/alarmprofilesforlist',
      method: 'GET',
      headers
    });
  };

  /**
   * getAllAlarmComments
   * Gets the existing comments on a list of alarms.
   */
  getAllAlarmCommentsUsingPOST = (
    requestParameters: GetAllAlarmCommentsUsingPOSTRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'alarmsCommentsRequest', 'getAllAlarmCommentsUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/alarms/comments',
      method: 'POST',
      headers,
      body: requestParameters.alarmsCommentsRequest
    });
  };

  /**
   * getApplianceAlarmProfileDetails
   * Gets the alarm profile details.
   */
  getApplianceAlarmProfileDetailsUsingGET = (
    requestParameters: GetApplianceAlarmProfileDetailsUsingGETRequest
  ): Observable<ApplianceAlarmProfileDetailsResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getApplianceAlarmProfileDetailsUsingGET');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getApplianceAlarmProfileDetailsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceAlarmProfileDetailsResponse>({
      path: '/alarmprofile/{virtualFridgeId}/{gatewayId}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getFilteredMergedAlarmList
   * Gets list with all alarms combined or just for single types. They can be filtered.
   */
  getFilteredMergedAlarmListUsingPUT = (
    requestParameters: GetFilteredMergedAlarmListUsingPUTRequest
  ): Observable<FilteredMergedAlarmsResponse> => {
    throwIfRequired(requestParameters, 'filters', 'getFilteredMergedAlarmListUsingPUT');
    throwIfRequired(requestParameters, 'continuationAlarmToken', 'getFilteredMergedAlarmListUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.filters && { filters: requestParameters.filters }),
      ...(requestParameters.from && { from: (requestParameters.from as any).toISOString() }),
      ...(requestParameters.gatewayIds && { gatewayIds: requestParameters.gatewayIds }),
      ...(requestParameters.groupIds && { groupIds: requestParameters.groupIds }),
      ...(requestParameters.loggerVfIds && { loggerVfIds: requestParameters.loggerVfIds }),
      ...(requestParameters.selected && { selected: requestParameters.selected }),
      ...(requestParameters.smVfIds && { smVfIds: requestParameters.smVfIds }),
      ...(requestParameters.to && { to: (requestParameters.to as any).toISOString() }),
      ...(requestParameters.vfIds && { vfIds: requestParameters.vfIds })
    };

    return this.request<FilteredMergedAlarmsResponse>({
      path: '/filteredmergedalarms',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.continuationAlarmToken
    });
  };

  /**
   * getGatewayProfileDetails
   * Gets the gateway profile details.
   */
  getGatewayProfileDetailsUsingGET = (
    requestParameters: GetGatewayProfileDetailsUsingGETRequest
  ): Observable<GatewayAlarmProfile> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getGatewayProfileDetailsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<GatewayAlarmProfile>({
      path: '/gatewayprofile/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getMergedAlarmList
   * Gets a device alarm list by virtualFridgeIds list, the request can specify the number of alarms fetched.
   */
  getMergedAlarmListUsingGET = (
    requestParameters: GetMergedAlarmListUsingGETRequest
  ): Observable<MergedAlarmsResponse> => {
    throwIfRequired(requestParameters, 'from', 'getMergedAlarmListUsingGET');
    throwIfRequired(requestParameters, 'to', 'getMergedAlarmListUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MergedAlarmsResponse>({
      path: '/mergedalarms/{from}/{to}'
        .replace('{from}', encodeURI(requestParameters.from))
        .replace('{to}', encodeURI(requestParameters.to)),
      method: 'GET',
      headers
    });
  };

  /**
   * getMergedAlarmStats
   * Gets the current device and gateway alarm statistics object.
   */
  getMergedAlarmStatsUsingGET = (): Observable<MergedAlarmStatsResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MergedAlarmStatsResponse>({
      path: '/mergedalarms/stats',
      method: 'GET',
      headers
    });
  };

  /**
   * getSmartModuleAlarmsSettings
   * Gets SmartModule alarm profile.
   */
  getSmartModuleAlarmsSettingsUsingGET = (
    requestParameters: GetSmartModuleAlarmsSettingsUsingGETRequest
  ): Observable<SmartModuleAlarmSettingsResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getSmartModuleAlarmsSettingsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleAlarmSettingsResponse>({
      path: '/smartmodule/alarmsettings/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getSmartModuleNotificationSettings
   * Gets SmartModule notification settings.
   */
  getSmartModuleNotificationSettingsUsingGET = (
    requestParameters: GetSmartModuleNotificationSettingsUsingGETRequest
  ): Observable<NotificationSettingsResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getSmartModuleNotificationSettingsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<NotificationSettingsResponse>({
      path: '/smartmodule/notification/settings/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * setAlarmReadState
   * Sets the passed-in alarm state \'read/unread\'.
   */
  setAlarmReadStateUsingPUT = (requestParameters: SetAlarmReadStateUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmReadStateRequest', 'setAlarmReadStateUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/alarm/readstate',
      method: 'PUT',
      headers,
      body: requestParameters.alarmReadStateRequest
    });
  };

  /**
   * updateApplianceAlarmProfile
   * Updates an alarm profile.
   */
  updateApplianceAlarmProfileUsingPUT = (
    requestParameters: UpdateApplianceAlarmProfileUsingPUTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'applianceAlarmProfileUpdateRequest', 'updateApplianceAlarmProfileUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/alarmprofile/virtualfridge',
      method: 'PUT',
      headers,
      body: requestParameters.applianceAlarmProfileUpdateRequest
    });
  };

  /**
   * updateGatewayProfile
   * Updates an gateway profile.
   */
  updateGatewayProfileUsingPUT = (requestParameters: UpdateGatewayProfileUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'gatewayAlarmProfileUpdateRequest', 'updateGatewayProfileUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/alarmprofile/gateway',
      method: 'PUT',
      headers,
      body: requestParameters.gatewayAlarmProfileUpdateRequest
    });
  };

  /**
   * updateSmartModuleCloudAlarmsSettings
   * Update SmartModule zone additional cloud alarms.
   */
  updateSmartModuleCloudAlarmsSettingsUsingPUT = (
    requestParameters: UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest
  ): Observable<boolean> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'updateSmartModuleCloudAlarmsSettingsUsingPUT');
    throwIfRequired(
      requestParameters,
      'smartModuleZoneSettingsRequest',
      'updateSmartModuleCloudAlarmsSettingsUsingPUT'
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/smartmodule/alarmsettings/cloudalarms/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.smartModuleZoneSettingsRequest
    });
  };

  /**
   * updateSmartModuleNotificationSettings
   * Create/Update SmartModule notification settings.
   */
  updateSmartModuleNotificationSettingsUsingPUT = (
    requestParameters: UpdateSmartModuleNotificationSettingsUsingPUTRequest
  ): Observable<boolean> => {
    throwIfRequired(requestParameters, 'notificationSettingsRequest', 'updateSmartModuleNotificationSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/smartmodule/notification/settings',
      method: 'PUT',
      headers,
      body: requestParameters.notificationSettingsRequest
    });
  };

  /**
   * updateSmartModuleSystemSettings
   * Update SmartModule alarm profile - system unavailability.
   */
  updateSmartModuleSystemSettingsUsingPUT = (
    requestParameters: UpdateSmartModuleSystemSettingsUsingPUTRequest
  ): Observable<boolean> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'updateSmartModuleSystemSettingsUsingPUT');
    throwIfRequired(requestParameters, 'smartModuleSystemSettingsRequest', 'updateSmartModuleSystemSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/smartmodule/alarmsettings/system/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.smartModuleSystemSettingsRequest
    });
  };
}
