import _Vue from 'vue';

import { Language } from '@/I18nStore';
import CountryHelper, { SupportedCountry } from '@/components/countries/CountryHelper';

/**
 * Defines the components that we support
 */
export type PPT_Component = 'SCH';

/**
 * The companies we're supporting.
 */
export type PPT_Company = 'liebherr' | 'wepa';

/**
 * The thinkable locations.
 */
export type PPT_Location = '*' | 'dashboard' | 'settings' | 'filters' | 'overview';

/**
 * Puppeteer class. Controls the visibility of UI components depending on company.
 *
 *
 *
 * @example
 * In your TS class, you can always ask:
 *
 * import { Puppeteer } from '@/Puppeteer';
 *
 * Puppeteer.isLiebherr() // true or false
 * Puppeteer.isWepa() // true or false
 *
 * You can also ask for the visibility of components, here the SCH:
 * Puppeteer.showSCH() // true or false
 *
 * You can also ask for the visibility on certain locations:
 * Puppeteer.showSCH('dashboard') // true or false
 *
 * If there is a location defined and it matches the asked location, it will be true.
 * The special location ('*') acts as a sign for "everywhere".
 *
 * Puppeteer.wepaMap.set('SCH', []);         // SCH hidden everywhere for WEPA
 * Puppeteer.liebherrMap.set('SCH', ['*']);  // SCH shown everywhere for Liebherr
 *
 * In Vue files:
 * v-if="$ppt.showSCH()"
 * v-if="$ppt.show('SCH')"
 *
 * In TS files of Vue components:
 * const shown = this.$ppt.showSCH(); or:   // usable without import
 *
 * import { Puppeteer } from '@/Puppeteer';
 * const shown = Puppeteer.showSCH()        // needs an import, can also be used on standalone TS classes.
 *
 *
 * @export
 * @class Puppeteer
 */
export class Puppeteer {
  // static reference to this for the static methods
  public static $ppt: Puppeteer = undefined;

  private readonly wepaMap: Map<PPT_Component, PPT_Location[]> = new Map<PPT_Component, PPT_Location[]>();
  private readonly liebherrMap: Map<PPT_Component, PPT_Location[]> = new Map<PPT_Component, PPT_Location[]>();
  private readonly companyMap: Map<PPT_Company, Map<PPT_Component, PPT_Location[]>> = new Map<
    PPT_Company,
    Map<PPT_Component, PPT_Location[]>
  >();

  /**
   * The possible languages that we can handle.
   *
   * @private
   * @type {Language[]}
   * @memberof Puppeteer
   */
  private static readonly supportedLanguages: Language[] = [
    { name: 'Deutsch', code: 'de-DE', language: 'de', country: 'DE' },
    { name: 'Čeština', code: 'cs-CZ', language: 'cs', country: 'CZ' },
    { name: 'English (US)', code: 'en-US', language: 'en', country: 'US' },
    { name: 'English (GB)', code: 'en-GB', language: 'en', country: 'GB' },
    { name: 'Español', code: 'es-ES', language: 'es', country: 'ES' },
    { name: 'Français', code: 'fr-FR', language: 'fr', country: 'FR' },
    { name: 'Polski', code: 'pl-PL', language: 'pl', country: 'PL' },
    { name: 'Български', code: 'bg-BG', language: 'bg', country: 'BG' },
    { name: 'Română', code: 'ro-RO', language: 'ro', country: 'RO' },
    { name: 'Ελληνικά', code: 'el-GR', language: 'el', country: 'GR' },
    { name: 'Slovenščina', code: 'sl-SI', language: 'sl', country: 'SI' },
    { name: 'Slovenčina', code: 'sk-SK', language: 'sk', country: 'SK' },
    { name: 'Magyar', code: 'hu-HU', language: 'hu', country: 'HU' }
  ];

  /**
   * Definition of all available countries.
   *
   * @static
   * @type {string}
   * @memberof CountryHelper
   */
  public static readonly DE: string = 'DE';
  public static readonly AT: string = 'AT';
  public static readonly GB: string = 'GB';
  public static readonly CH: string = 'CH';
  public static readonly PL: string = 'PL';
  public static readonly ES: string = 'ES';
  public static readonly DK: string = 'DK';
  public static readonly IT: string = 'IT';
  public static readonly FR: string = 'FR';
  public static readonly NL: string = 'NL';
  public static readonly BE: string = 'BE';
  public static readonly LU: string = 'LU';
  public static readonly CZ: string = 'CZ';
  public static readonly HU: string = 'HU';
  public static readonly SI: string = 'SI';
  public static readonly SK: string = 'SK';
  public static readonly BG: string = 'BG';
  public static readonly RO: string = 'RO';
  public static readonly GR: string = 'GR';
  // Official code for unknown countries:
  public static readonly XX: string = 'XX';
  public static readonly DEFAULT: string = 'DE';

  /**
   * The possible country codes that the user can choose in the dialog.
   *
   * @private
   * @type {SupportedCountry[]}
   * @memberof Puppeteer
   */
  private static readonly supportedCountries: string[] = [
    Puppeteer.DE,
    Puppeteer.AT,
    Puppeteer.GB,
    Puppeteer.CH,
    Puppeteer.PL,
    Puppeteer.ES,
    Puppeteer.DK,
    Puppeteer.IT,
    Puppeteer.FR,
    Puppeteer.NL,
    Puppeteer.BE,
    Puppeteer.LU,
    Puppeteer.CZ,
    Puppeteer.HU,
    Puppeteer.SI,
    Puppeteer.SK,
    Puppeteer.BG,
    Puppeteer.RO,
    Puppeteer.GR
  ];

  /**
   * Creates an instance of Puppeteer.
   * @memberof Puppeteer
   */
  constructor() {
    // Shouldn't happen
    if (Puppeteer.$ppt) {
      return;
    }

    Puppeteer.$ppt = this;

    this.companyMap.set('liebherr', this.liebherrMap);
    this.companyMap.set('wepa', this.wepaMap);

    this.wepaMap.set('SCH', []);
    this.liebherrMap.set('SCH', ['*']);
  }

  /**
   * Convenience method for the SCH.
   *
   * @static
   * @param {PPT_Location} [location='*'] the location. Leave empty for "everywhere".
   * @return {*}  {boolean} true if shown, otherwise false.
   * @memberof Puppeteer
   */
  public static showSCH(location: PPT_Location = '*'): boolean {
    return Puppeteer.$ppt.show('SCH', location);
  }

  /**
   * Pass in a context and a component and see whether it should be shown.
   *
   * @static
   * @param {PPT_Component} component The component. See PPT_Component for supported components.
   * @param {PPT_Location} [location='*'] the location. Leave empty for "everywhere".
   * @return {*}  {boolean} true if shown, otherwise false.
   * @memberof Puppeteer
   */
  public static show(component: PPT_Component, location: PPT_Location = '*'): boolean {
    return Puppeteer.$ppt.show(component, location);
  }

  /**
   * Tests if we are looking at Liebherr.
   *
   * @static
   * @return {*}  {boolean} true if Liebherr.
   * @memberof Puppeteer
   */
  public static isLiebherr(): boolean {
    return import.meta.env.VITE_PRODUCT === 'liebherr';
  }

  /**
   * Tests if we are looking at WEPA.
   *
   * @static
   * @return {*}  {boolean} true if WEPA.
   * @memberof Puppeteer
   */
  public static isWepa(): boolean {
    return import.meta.env.VITE_PRODUCT === 'wepa';
  }

  /**
   * Convenience method for the SCH.
   *
   * @param {PPT_Location} [location='*'] the location. Leave empty for "everywhere".
   * @return {*}  {boolean} true if shown, otherwise false.
   * @memberof Puppeteer
   */
  public showSCH(location: PPT_Location = '*'): boolean {
    return this.show('SCH', location);
  }

  /**
   * Pass in a context and a component and see whether it should be shown.
   *
   * @param {PPT_Component} component The component. See PPT_Component for supported components.
   * @param {PPT_Location} [location='*'] the location. Leave empty for "everywhere".
   * @return {*}  {boolean} true if shown, otherwise false.
   * @memberof Puppeteer
   */
  public show(component: PPT_Component, location: PPT_Location = '*'): boolean {
    if (this.isWepa()) {
      return this.showComponent('wepa', component, location);
    }

    return this.showComponent('liebherr', component, location);
  }

  /**
   * Tests if we are looking at Liebherr.
   *
   * @static
   * @return {*}  {boolean} true if Liebherr.
   * @memberof Puppeteer
   */
  public isLiebherr(): boolean {
    return import.meta.env.VITE_PRODUCT === 'liebherr';
  }

  /**
   * Tests if we are looking at WEPA.
   *
   * @static
   * @return {*}  {boolean} true if WEPA.
   * @memberof Puppeteer
   */
  public isWepa(): boolean {
    return import.meta.env.VITE_PRODUCT === 'wepa';
  }

  /**
   * Determines whether a component should be shown for the company.
   *
   * @private
   * @static
   * @param {PPT_Company} company The company.
   * @param {PPT_Component} component The component.
   * @return {*}  {boolean} true if visible, also true as default if not defined.
   * @memberof Puppeteer
   */
  private showComponent(company: PPT_Company, component: PPT_Component, location: PPT_Location): boolean {
    const companyMap = this.companyMap.get(company);

    if (!companyMap.has(component)) {
      return true;
    }

    const locations: PPT_Location[] = companyMap.get(component);
    return locations.indexOf('*') >= 0 || locations.indexOf(location) >= 0;
  }

  /**
   * Retrieves the available languages. For WEPA, we restrict to de-DE and en-GB. We add en-US on development systems.
   *
   * @return {*}  {Language[]}
   * @memberof Puppeteer
   */
  public static getAvailableLanguageDefinitions(): Language[] {
    let availableLanguages: Language[] = [...Puppeteer.supportedLanguages];

    if (this.isWepa()) {
      availableLanguages = availableLanguages.filter(language => {
        return language.country === 'US' || language.country === 'DE' || language.country === 'FR';
      });
    }

    return availableLanguages;
  }

  /**
   * Gets the supported countries.
   *
   * @static
   * @return {*}  {SupportedCountry[]}
   * @memberof Puppeteer
   */
  public static getSupportedCountries(): SupportedCountry[] {
    const supportedCountriesBase: string[] = [...Puppeteer.supportedCountries];

    // Create that late to avoid a circular dependency with CountryHelper
    const supportedCountries: SupportedCountry[] = [];

    supportedCountriesBase.forEach(countryBase => {
      supportedCountries.push({
        name: CountryHelper.getCountryName(countryBase),
        code: countryBase
      });
    });

    return supportedCountries;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $ppt: Puppeteer;
  }
}

export default {
  install: (Vue: typeof _Vue): void => {
    const puppeteer = new Puppeteer();

    // Make available to Vue components
    Vue.prototype.$ppt = puppeteer;
  }
};
