// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AccessCheckResponse
 */
export interface AccessCheckResponse {
  /**
   * @type {string}
   * @memberof AccessCheckResponse
   */
  context?: string;
  /**
   * @type {string}
   * @memberof AccessCheckResponse
   */
  role?: AccessCheckResponseRoleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AccessCheckResponseRoleEnum {
  ADMIN = 'ADMIN',
  USER = 'USER',
  ERRORCOMPANYVERIFICATION = 'ERROR_COMPANYVERIFICATION',
  ERRORUSERVERIFICATION = 'ERROR_USERVERIFICATION',
  ERRORB2CUSER = 'ERROR_B2CUSER',
  ERRORNOACCESS = 'ERROR_NOACCESS',
  ERRORWRONGCOMPANY = 'ERROR_WRONGCOMPANY'
}
