// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  AutomatedReportSettingsRequest,
  AutomatedReportSettingsResponse,
  ContinuationReportToken,
  FilteredReportDetailsResponse,
  MultipleReportRequest,
  ReportDetails,
  ReportZipResponse,
  UpdateAutoReportSettings
} from '../models';

export interface CreateAutomatedReportUsingPOSTRequest {
  automatedReportSettingsRequest: AutomatedReportSettingsRequest;
}

export interface CreateReportsUsingPOSTRequest {
  reportType: CreateReportsUsingPOSTReportTypeEnum;
  multipleReportRequest: MultipleReportRequest;
}

export interface DeleteReportsUsingDELETERequest {
  reportIds: Array<string>;
}

export interface GetAllReportsUsingGETRequest {
  from: number;
  to: number;
}

export interface GetFilteredAllReportsUsingPUTRequest {
  continuationReportToken: ContinuationReportToken;
  deviceFilter?: string;
  filterGroupIds?: Array<string>;
  filterMSIds?: Array<string>;
  userNames?: Array<string>;
}

export interface GetFilteredReportsByMeasureSiteUsingPUTRequest {
  virtualFridgeId: string;
  continuationReportToken: ContinuationReportToken;
}

export interface GetZippedReportsUsingPUTRequest {
  requestBody: Array<string>;
}

export interface ReplaceAutomatedReportUsingPOSTRequest {
  automatedReportSettingsRequest: AutomatedReportSettingsRequest;
}

export interface UpdateAutomatedReportUsingPUTRequest {
  updateAutoReportSettings: UpdateAutoReportSettings;
}

/**
 * no description
 */
export class ReportingControllerApi extends BaseAPI {
  /**
   * createAutomatedReport
   * Creates automated reports setting.
   */
  createAutomatedReportUsingPOST = (
    requestParameters: CreateAutomatedReportUsingPOSTRequest
  ): Observable<AutomatedReportSettingsResponse> => {
    throwIfRequired(requestParameters, 'automatedReportSettingsRequest', 'createAutomatedReportUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AutomatedReportSettingsResponse>({
      path: '/admin/create/autoreport',
      method: 'POST',
      headers,
      body: requestParameters.automatedReportSettingsRequest
    });
  };

  /**
   * createReports
   * Creates a report request
   */
  createReportsUsingPOST = (requestParameters: CreateReportsUsingPOSTRequest): Observable<Array<ReportDetails>> => {
    throwIfRequired(requestParameters, 'reportType', 'createReportsUsingPOST');
    throwIfRequired(requestParameters, 'multipleReportRequest', 'createReportsUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Array<ReportDetails>>({
      path: '/report/create/{reportType}'.replace('{reportType}', encodeURI(requestParameters.reportType)),
      method: 'POST',
      headers,
      body: requestParameters.multipleReportRequest
    });
  };

  /**
   * deleteAutomatedReport
   * Deletes automated report.
   */
  deleteAutomatedReportUsingDELETE = (): Observable<void> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/delete/autoreport',
      method: 'DELETE',
      headers
    });
  };

  /**
   * deleteReports
   * Deletes reports of given IDs
   */
  deleteReportsUsingDELETE = (requestParameters: DeleteReportsUsingDELETERequest): Observable<void> => {
    throwIfRequired(requestParameters, 'reportIds', 'deleteReportsUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.reportIds && { reportIds: requestParameters.reportIds })
    };

    return this.request<void>({
      path: '/report/delete',
      method: 'DELETE',
      headers,
      query
    });
  };

  /**
   * getAllReports
   * Gets all reports
   */
  getAllReportsUsingGET = (requestParameters: GetAllReportsUsingGETRequest): Observable<Array<ReportDetails>> => {
    throwIfRequired(requestParameters, 'from', 'getAllReportsUsingGET');
    throwIfRequired(requestParameters, 'to', 'getAllReportsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Array<ReportDetails>>({
      path: '/report/all/{from}/{to}'
        .replace('{from}', encodeURI(requestParameters.from))
        .replace('{to}', encodeURI(requestParameters.to)),
      method: 'GET',
      headers
    });
  };

  /**
   * getAutomatedReport
   * Gets automated reports setting.
   */
  getAutomatedReportUsingGET = (): Observable<AutomatedReportSettingsResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AutomatedReportSettingsResponse>({
      path: '/admin/autoreport',
      method: 'GET',
      headers
    });
  };

  /**
   * getFilteredAllReports
   * Gets all reports
   */
  getFilteredAllReportsUsingPUT = (
    requestParameters: GetFilteredAllReportsUsingPUTRequest
  ): Observable<FilteredReportDetailsResponse> => {
    throwIfRequired(requestParameters, 'continuationReportToken', 'getFilteredAllReportsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.deviceFilter && { deviceFilter: requestParameters.deviceFilter }),
      ...(requestParameters.filterGroupIds && { filterGroupIds: requestParameters.filterGroupIds }),
      ...(requestParameters.filterMSIds && { filterMSIds: requestParameters.filterMSIds }),
      ...(requestParameters.userNames && { userNames: requestParameters.userNames })
    };

    return this.request<FilteredReportDetailsResponse>({
      path: '/report/filtered/all',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.continuationReportToken
    });
  };

  /**
   * getFilteredReportsByMeasureSite
   * Gets reports by appliance
   */
  getFilteredReportsByMeasureSiteUsingPUT = (
    requestParameters: GetFilteredReportsByMeasureSiteUsingPUTRequest
  ): Observable<FilteredReportDetailsResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getFilteredReportsByMeasureSiteUsingPUT');
    throwIfRequired(requestParameters, 'continuationReportToken', 'getFilteredReportsByMeasureSiteUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<FilteredReportDetailsResponse>({
      path: '/report/filtered/appliance/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.continuationReportToken
    });
  };

  /**
   * getZippedReports
   * Download multiple reports as a zip file.
   */
  getZippedReportsUsingPUT = (requestParameters: GetZippedReportsUsingPUTRequest): Observable<ReportZipResponse> => {
    throwIfRequired(requestParameters, 'requestBody', 'getZippedReportsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ReportZipResponse>({
      path: '/report/zipped',
      method: 'PUT',
      headers,
      body: requestParameters.requestBody
    });
  };

  /**
   * replaceAutomatedReport
   * Creates automated reports setting.
   */
  replaceAutomatedReportUsingPOST = (
    requestParameters: ReplaceAutomatedReportUsingPOSTRequest
  ): Observable<AutomatedReportSettingsResponse> => {
    throwIfRequired(requestParameters, 'automatedReportSettingsRequest', 'replaceAutomatedReportUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AutomatedReportSettingsResponse>({
      path: '/admin/replace/autoreport',
      method: 'POST',
      headers,
      body: requestParameters.automatedReportSettingsRequest
    });
  };

  /**
   * updateAutomatedReport
   * Updates automated reports setting.
   */
  updateAutomatedReportUsingPUT = (requestParameters: UpdateAutomatedReportUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'updateAutoReportSettings', 'updateAutomatedReportUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/update/autoreport',
      method: 'PUT',
      headers,
      body: requestParameters.updateAutoReportSettings
    });
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CreateReportsUsingPOSTReportTypeEnum {
  HACCP = 'HACCP',
  TEMPCONFORMITY = 'TEMPCONFORMITY'
}
