import './init';

import Vue from 'vue';
import './plugins/vuetify';

import Vuetify from 'vuetify';
Vue.use(Vuetify);

import VueMask from 'v-mask';
Vue.use(VueMask);

// Looks unused, but needed to get the trick above going
import CubeLogger from '@/Logger';
Vue.use(CubeLogger);

import Formatters from '@/Formatters';
Vue.use(Formatters);

// Used to detect mobile devices
import MobileHelper from './router/MobileHelper';

import { createPinia, PiniaVuePlugin } from 'pinia';
Vue.use(PiniaVuePlugin);
export const pinia = createPinia();

// Language control and switching
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n: VueI18n = new VueI18n();

import { useI18nStore } from '@/I18nStore';

// Router controls the app
import VueRouter from 'vue-router';
import router from '@/router/router';
Vue.use(VueRouter);

// VueJS stuff
import '@/directives';
Vue.config.productionTip = false;

// General CSS, valid for all companies
import '@/assets/common.css';
// Company-specific CSS, copied by vue.config.js
import '@/assets/global.css';

import EnvironmentHandler from './EnvironmentHandler';
// Late import to avoid timing issues
import App from '@/App.vue';
import MobileApp from '@/mobile/MobileApp.vue';
import VueApexCharts from 'vue-apexcharts';
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';

import Puppeteer from './Puppeteer';
Vue.use(Puppeteer);

const LIEBHERR = import.meta.env.VITE_PRODUCT === 'liebherr';

const analyticsDebug: boolean = LIEBHERR ? import.meta.env.VITE_ANALYTICS_DEBUG === 'true' : null;
const analyticsId: string = LIEBHERR ? import.meta.env.VITE_GOOGLE_TAG_MANAGER : null;

import VueGtm from '@gtm-support/vue2-gtm';

LIEBHERR
  ? Vue.use(VueGtm, {
      id: analyticsId,
      enabled: true,
      debug: analyticsDebug,
      vueRouter: router
    })
  : null;

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.use(LottieVuePlayer);

// We add a "?date=1619675159013" (example) to "bust" caching
const environmentUrl: string = window.location.origin + '/environment.json?date=' + new Date().getTime().toString();
let app: Vue;

window
  .fetch(environmentUrl)
  .then(response => {
    return response.json();
  })
  .then(json => {
    EnvironmentHandler.init(json);

    if (MobileHelper.isMobile) {
      app = new Vue({
        el: '#app',
        router,
        pinia,
        i18n,
        render: h => h(MobileApp)
      });
    } else {
      app = new Vue({
        el: '#app',
        router,
        pinia,
        i18n,
        render: h => h(App)
      });
    }

    const i18nStore = useI18nStore();
    i18nStore.init(i18n);
  });
