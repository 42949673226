// Vite (unlike Webpack) does not the window.global
window.global ||= window;

if (import.meta.env.VITE_ENVIRONMENT === 'localforward') {
  document.title = 'LOCALFORWARD';
}

if (import.meta.env.VITE_ENVIRONMENT === 'localhost') {
  document.title = 'LOCALHOST';
}
