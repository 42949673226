// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  BinderApplianceRequest,
  CheckBsdbRequest,
  CheckGatewayResponse,
  CreateBsdbRequest,
  CreateGatewayResponse,
  ErrorDto,
  ExtendedGatewayState,
  FirmwareRestrictionsResponse,
  GatewayConfigurationResponse,
  GatewayStatesResponse,
  HttpStatusCode,
  RegisteredBinderAppliance,
  SaveApplianceRequest,
  SaveApplianceResponse,
  SmartModuleLaunchResponse,
  SmartModuleRequest,
  SmartModuleResponse,
  UUIDListRequest
} from '../models';

export interface CheckBsdbUsingPUTRequest {
  checkBsdbRequest: CheckBsdbRequest;
}

export interface CheckFirmwareRestrictionsUsingGETRequest {
  gatewayId: string;
}

export interface CreateBsdbUsingPUTRequest {
  createBsdbRequest: CreateBsdbRequest;
}

export interface DeleteApplianceUsingDELETERequest {
  gatewayId: string;
  virtualFridgeId: string;
}

export interface DeleteBinderApplianceUsingDELETERequest {
  gatewayId: string;
  virtualFridgeId: string;
}

export interface DeleteGatewayUsingDELETERequest {
  gatewayId: string;
}

export interface DeleteVirtualMeasureSiteUsingDELETERequest {
  virtualFridgeId: string;
}

export interface GetBsdbConfigUsingGETRequest {
  gatewayId: string;
}

export interface GetExtendedGatewayStateUsingGETRequest {
  gatewayId: string;
}

export interface GetGatewayStatesForListUsingPOSTRequest {
  uUIDListRequest: UUIDListRequest;
}

export interface OffBoardSmartModuleUsingPOSTRequest {
  serialNumber: string;
}

export interface SaveApplianceUsingPOSTRequest {
  gatewayId: string;
  groupId: string;
  saveApplianceRequest: SaveApplianceRequest;
}

export interface SaveBinderApplianceUsingPOSTRequest {
  gatewayId: string;
  binderApplianceRequest: BinderApplianceRequest;
}

export interface SaveSmartModuleUsingPOSTRequest {
  serialNumber: string;
  smartModuleRequest: SmartModuleRequest;
}

export interface ScanGatewayOldUsingGETRequest {
  gatewayId: string;
  startScan: boolean;
}

export interface ScanGatewayUsingGETRequest {
  gatewayId: string;
  binderAppliance?: boolean;
  ipAddress?: string;
}

export interface StartSmartModuleOnboardingUsingPOSTRequest {
  correlationId: string;
  serialNumber: string;
}

/**
 * no description
 */
export class BsdbControllerApi extends BaseAPI {
  /**
   * checkBsdb
   * Checks a BSDB/gateway by serial number and passcode if given.
   */
  checkBsdbUsingPUT = (requestParameters: CheckBsdbUsingPUTRequest): Observable<CheckGatewayResponse> => {
    throwIfRequired(requestParameters, 'checkBsdbRequest', 'checkBsdbUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<CheckGatewayResponse>({
      path: '/bsdb/check',
      method: 'PUT',
      headers,
      body: requestParameters.checkBsdbRequest
    });
  };

  /**
   * checkFirmwareRestrictions
   * Checks whether the current gateway has restrictions in scan and onboarding.
   */
  checkFirmwareRestrictionsUsingGET = (
    requestParameters: CheckFirmwareRestrictionsUsingGETRequest
  ): Observable<FirmwareRestrictionsResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'checkFirmwareRestrictionsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<FirmwareRestrictionsResponse>({
      path: '/admin/bsdb/scan/{gatewayId}/check'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * createBsdb
   * Creates necessary settings for given BSDB.
   */
  createBsdbUsingPUT = (requestParameters: CreateBsdbUsingPUTRequest): Observable<CreateGatewayResponse> => {
    throwIfRequired(requestParameters, 'createBsdbRequest', 'createBsdbUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<CreateGatewayResponse>({
      path: '/admin/bsdb/create',
      method: 'PUT',
      headers,
      body: requestParameters.createBsdbRequest
    });
  };

  /**
   * deleteAppliance
   * Deletes an appliance
   */
  deleteApplianceUsingDELETE = (requestParameters: DeleteApplianceUsingDELETERequest): Observable<void> => {
    throwIfRequired(requestParameters, 'gatewayId', 'deleteApplianceUsingDELETE');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'deleteApplianceUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/bsdb/appliance/{gatewayId}/{virtualFridgeId}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'DELETE',
      headers
    });
  };

  /**
   * deleteBinderAppliance
   * Deletes an appliance
   */
  deleteBinderApplianceUsingDELETE = (
    requestParameters: DeleteBinderApplianceUsingDELETERequest
  ): Observable<HttpStatusCode> => {
    throwIfRequired(requestParameters, 'gatewayId', 'deleteBinderApplianceUsingDELETE');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'deleteBinderApplianceUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<HttpStatusCode>({
      path: '/admin/bsdb/binder/{gatewayId}/{virtualFridgeId}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'DELETE',
      headers
    });
  };

  /**
   * deleteGateway
   * Deletes an appliance
   */
  deleteGatewayUsingDELETE = (requestParameters: DeleteGatewayUsingDELETERequest): Observable<void> => {
    throwIfRequired(requestParameters, 'gatewayId', 'deleteGatewayUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/gateway/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'DELETE',
      headers
    });
  };

  /**
   * deleteVirtualMeasureSite
   * Deletes a virtual measure site. Not applicable for connectables.
   */
  deleteVirtualMeasureSiteUsingDELETE = (
    requestParameters: DeleteVirtualMeasureSiteUsingDELETERequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'deleteVirtualMeasureSiteUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/bsdb/measuresite/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'DELETE',
      headers
    });
  };

  /**
   * getBsdbConfig
   * Gets the config for a given BSDB/gateway.
   */
  getBsdbConfigUsingGET = (
    requestParameters: GetBsdbConfigUsingGETRequest
  ): Observable<GatewayConfigurationResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getBsdbConfigUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<GatewayConfigurationResponse>({
      path: '/admin/bsdb/config/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getExtendedGatewayState
   * Gets the extended gateway state for a UUID.
   */
  getExtendedGatewayStateUsingGET = (
    requestParameters: GetExtendedGatewayStateUsingGETRequest
  ): Observable<ExtendedGatewayState> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getExtendedGatewayStateUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ExtendedGatewayState>({
      path: '/gatewaystate/extended/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getGatewayStatesForList
   * Gets the gateway states for a list of gateway UUIDs.
   */
  getGatewayStatesForListUsingPOST = (
    requestParameters: GetGatewayStatesForListUsingPOSTRequest
  ): Observable<GatewayStatesResponse> => {
    throwIfRequired(requestParameters, 'uUIDListRequest', 'getGatewayStatesForListUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<GatewayStatesResponse>({
      path: '/gatewaystates',
      method: 'POST',
      headers,
      body: requestParameters.uUIDListRequest
    });
  };

  /**
   * offBoardSmartModule
   * Off-Boards a Smart module
   */
  offBoardSmartModuleUsingPOST = (requestParameters: OffBoardSmartModuleUsingPOSTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'serialNumber', 'offBoardSmartModuleUsingPOST');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/smartmodule/fridge/offboard/{serialNumber}'.replace(
        '{serialNumber}',
        encodeURI(requestParameters.serialNumber)
      ),
      method: 'POST',
      headers
    });
  };

  /**
   * saveAppliance
   * Saves an appliance.
   */
  saveApplianceUsingPOST = (requestParameters: SaveApplianceUsingPOSTRequest): Observable<SaveApplianceResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'saveApplianceUsingPOST');
    throwIfRequired(requestParameters, 'groupId', 'saveApplianceUsingPOST');
    throwIfRequired(requestParameters, 'saveApplianceRequest', 'saveApplianceUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SaveApplianceResponse>({
      path: '/admin/bsdb/appliance/{gatewayId}/{groupId}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{groupId}', encodeURI(requestParameters.groupId)),
      method: 'POST',
      headers,
      body: requestParameters.saveApplianceRequest
    });
  };

  /**
   * saveBinderAppliance
   * Save binder appliance.
   */
  saveBinderApplianceUsingPOST = (
    requestParameters: SaveBinderApplianceUsingPOSTRequest
  ): Observable<RegisteredBinderAppliance> => {
    throwIfRequired(requestParameters, 'gatewayId', 'saveBinderApplianceUsingPOST');
    throwIfRequired(requestParameters, 'binderApplianceRequest', 'saveBinderApplianceUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<RegisteredBinderAppliance>({
      path: '/admin/bsdb/binderappliance/{gatewayId}/binder'.replace(
        '{gatewayId}',
        encodeURI(requestParameters.gatewayId)
      ),
      method: 'POST',
      headers,
      body: requestParameters.binderApplianceRequest
    });
  };

  /**
   * saveSmartModule
   * Save SmartModule
   */
  saveSmartModuleUsingPOST = (requestParameters: SaveSmartModuleUsingPOSTRequest): Observable<SmartModuleResponse> => {
    throwIfRequired(requestParameters, 'serialNumber', 'saveSmartModuleUsingPOST');
    throwIfRequired(requestParameters, 'smartModuleRequest', 'saveSmartModuleUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleResponse>({
      path: '/admin/smartmodule/fridge/{serialNumber}'.replace(
        '{serialNumber}',
        encodeURI(requestParameters.serialNumber)
      ),
      method: 'POST',
      headers,
      body: requestParameters.smartModuleRequest
    });
  };

  /**
   * scanGatewayOld
   * Gets the config for a given BSDB/gateway.
   */
  scanGatewayOldUsingGET = (
    requestParameters: ScanGatewayOldUsingGETRequest
  ): Observable<GatewayConfigurationResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'scanGatewayOldUsingGET');
    throwIfRequired(requestParameters, 'startScan', 'scanGatewayOldUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<GatewayConfigurationResponse>({
      path: '/admin/bsdb/scanold/{gatewayId}/{startScan}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{startScan}', encodeURI(requestParameters.startScan)),
      method: 'GET',
      headers
    });
  };

  /**
   * scanGateway
   * Start the scan for a given BSDB/gateway.
   */
  scanGatewayUsingGET = (requestParameters: ScanGatewayUsingGETRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'gatewayId', 'scanGatewayUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.binderAppliance && { binderAppliance: requestParameters.binderAppliance }),
      ...(requestParameters.ipAddress && { ipAddress: requestParameters.ipAddress })
    };

    return this.request<boolean>({
      path: '/admin/bsdb/scan/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * startSmartModuleOnboarding
   * Provision a new smart module fridge for given serial number
   */
  startSmartModuleOnboardingUsingPOST = (
    requestParameters: StartSmartModuleOnboardingUsingPOSTRequest
  ): Observable<SmartModuleLaunchResponse> => {
    throwIfRequired(requestParameters, 'correlationId', 'startSmartModuleOnboardingUsingPOST');
    throwIfRequired(requestParameters, 'serialNumber', 'startSmartModuleOnboardingUsingPOST');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleLaunchResponse>({
      path: '/admin/smartmodule/fridge/{correlationId}/{serialNumber}'
        .replace('{correlationId}', encodeURI(requestParameters.correlationId))
        .replace('{serialNumber}', encodeURI(requestParameters.serialNumber)),
      method: 'POST',
      headers
    });
  };
}
