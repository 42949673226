// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import { Granularity, HistoryResponse } from '../models';

export interface GetHistoryDataPointsUsingPUTRequest {
  applianceType: GetHistoryDataPointsUsingPUTApplianceTypeEnum;
  end: Date;
  start: Date;
  virtualFridgeId: string;
  zoneThingId: string;
  granularity: Granularity;
}

/**
 * no description
 */
export class HistoryControllerApi extends BaseAPI {
  /**
   * getHistoryDataPoints
   * Gets the datapoints for the chart
   */
  getHistoryDataPointsUsingPUT = (
    requestParameters: GetHistoryDataPointsUsingPUTRequest
  ): Observable<HistoryResponse> => {
    throwIfRequired(requestParameters, 'applianceType', 'getHistoryDataPointsUsingPUT');
    throwIfRequired(requestParameters, 'end', 'getHistoryDataPointsUsingPUT');
    throwIfRequired(requestParameters, 'start', 'getHistoryDataPointsUsingPUT');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getHistoryDataPointsUsingPUT');
    throwIfRequired(requestParameters, 'zoneThingId', 'getHistoryDataPointsUsingPUT');
    throwIfRequired(requestParameters, 'granularity', 'getHistoryDataPointsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.applianceType && { applianceType: requestParameters.applianceType }),
      ...(requestParameters.end && { end: (requestParameters.end as any).toISOString() }),
      ...(requestParameters.start && { start: (requestParameters.start as any).toISOString() }),
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId }),
      ...(requestParameters.zoneThingId && { zoneThingId: requestParameters.zoneThingId })
    };

    return this.request<HistoryResponse>({
      path: '/history/datapoints',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.granularity
    });
  };
}

/**
 * @export
 * @enum {string}
 */
export enum GetHistoryDataPointsUsingPUTApplianceTypeEnum {
  MODBUSFRIDGE = 'MODBUS_FRIDGE',
  BINDERFRIDGE = 'BINDER_FRIDGE',
  SMARTMODULEFRIDGE = 'SMART_MODULE_FRIDGE',
  LCVFRIDGE = 'LCV_FRIDGE',
  LOGGER = 'LOGGER'
}
