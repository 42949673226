// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApplianceZone, SensorMeasureSite } from './';

/**
 * @export
 * @interface MeasureSite
 */
export interface MeasureSite {
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  building?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  comment?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  compartmentType?: MeasureSiteCompartmentTypeEnum;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  department?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  deviceUuid?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  level?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  manufacturer?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  measureSiteType: MeasureSiteMeasureSiteTypeEnum;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  model?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  name?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  onboardingState?: MeasureSiteOnboardingStateEnum;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  roomNumber?: string;
  /**
   * @type {Array<SensorMeasureSite>}
   * @memberof MeasureSite
   */
  sensors?: Array<SensorMeasureSite>;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  serialNumber?: string;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  thingId?: string;
  /**
   * @type {boolean}
   * @memberof MeasureSite
   */
  twoZoneModel?: boolean;
  /**
   * @type {string}
   * @memberof MeasureSite
   */
  virtualFridgeId?: string;
  /**
   * @type {Array<ApplianceZone>}
   * @memberof MeasureSite
   */
  zones?: Array<ApplianceZone>;
}

/**
 * @export
 * @enum {string}
 */
export enum MeasureSiteCompartmentTypeEnum {
  COOLER = 'COOLER',
  FREEZER = 'FREEZER'
}
/**
 * @export
 * @enum {string}
 */
export enum MeasureSiteMeasureSiteTypeEnum {
  MODBUSFRIDGE = 'MODBUS_FRIDGE',
  BINDERFRIDGE = 'BINDER_FRIDGE',
  SMARTMODULEFRIDGE = 'SMART_MODULE_FRIDGE',
  LOGGER = 'LOGGER'
}
/**
 * @export
 * @enum {string}
 */
export enum MeasureSiteOnboardingStateEnum {
  VOID = 'VOID',
  AWAITONBOARDING = 'AWAIT_ONBOARDING',
  ONBOARDINGSTARTED = 'ONBOARDING_STARTED',
  ONBOARDED = 'ONBOARDED'
}
