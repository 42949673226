// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface LoggingConfig
 */
export interface LoggingConfig {
  /**
   * @type {number}
   * @memberof LoggingConfig
   */
  bufferSize?: number;
  /**
   * @type {boolean}
   * @memberof LoggingConfig
   */
  console?: boolean;
  /**
   * @type {string}
   * @memberof LoggingConfig
   */
  logLevel?: LoggingConfigLogLevelEnum;
  /**
   * @type {string}
   * @memberof LoggingConfig
   */
  serverFlush?: LoggingConfigServerFlushEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum LoggingConfigLogLevelEnum {
  Info = 'info',
  Debug = 'debug',
  Warn = 'warn',
  Error = 'error'
}
/**
 * @export
 * @enum {string}
 */
export enum LoggingConfigServerFlushEnum {
  Off = 'off',
  BufferSize = 'bufferSize',
  Warn = 'warn',
  Error = 'error'
}
